export const appNote = {
    HI: "Hi",
    WELCOME_TO_FALKON_SMS: "Welcome to Falkon SMS",
    TWOFACTORAUTH_ERROR: "You have entered incorrect OTP.",
    PASSWORD_CHANGED: "Password Changed!",
    CHANGEPASSWORD_SUCESS: "Your password has been changed successfully.",
    RESETPASSWORD_SUCESS: "Check your mail for a message with a link to update your password.\nThe link will expire in 2 hours",
    PASSWORD_UPDATE_SUCESS: "Your password has been updated",
    PASSWORD_LINK_EXPIRED: "Your link to reset your password has been expired. Please click below to reset it again",
    INACTIVE_USER: " Sorry, your email is locked. Please contact your organization admin or contact us for further queries.",
    INACTIVE_PHONE_NUMBER: "Sorry, your Phone Number is inactive. Please contact your organization admin or contact us for further queries.",
    INACTIVE_ORGANIZATION: " Sorry, your organization is disabled. Please contact us for further queries.",
    INVALID_EMAIL: "Sorry, We couldn’t find the account with that email.",
    INVALID_PASWORD: "Sorry, your password is incorrect.",
    OTHERS: "Oops, Something went wrong! Please try again after sometime.",
    ORG_ENABLE: "Are you sure you want to enable the organization?",
    ORG_DISABLE: "Are you sure you want to disable the organization?",
    PHONENUMBER_ENABLE: "Are you sure you want to enable this Phone Number?",
    PHONENUMBER_DISABLE: "Are you sure you want to disable this Phone Number?",
    GROUP_DELETE: 'This will permanently delete the scheduled messages associated with this group. Do you wish to proceed? ',
    GROUPS_DELETE: 'Deleting these groups will permanently delete all the scheduled messages related to these groups. Do you still want to delete? ',
    CONTACT_DELETE: 'Are you sure you want to delete this contact?',
    UNBLOCK_CONTACT: 'Are you sure you want to un-block this contact?',
    BLOCK_CONTACT: 'You will no longer receive messages from this contact. Are you sure you want to block this contact?',
    CONVERSATION_DELETE: 'Are you sure you want to delete this Conversation?',
    CONVERSATIONS_DELETE: 'Are you sure you want to delete these Conversations?',
    MESSAGE_DELETE: 'Are you sure you want to delete selected messages?',
    ADDITIONAL_PURCHASES: 'Additional Purchases',
    ADDITIONAL_FEATURES: 'Additional Features',
    OUTBOUND_DISABLE_DISPLAY: 'Are you sure you want to disable Out Bound For Teams?',
    FACTOR_DISABLE_DISPLAY: 'Are you sure you want to disable 2-factor authentication? It will disable 2 - factor authentication for all the users in your organization',
    FACTOR_ENABLE_DISPLAY: 'Are you sure you want to enable 2-factor authentication? It will enable 2-factor authentication for all the users in your organization',
    MANUAL_INVOICE_DISABLE_DISPLAY: 'Are you sure you want to disable manual invoice?',
    SINGLE_CONTACT: 'contact',
    MULTIPLE_CONTACTS: 'contacts',
    UNSUBSCRIBE: 'This contact has unsubscribed. You will not be able to send messages.',
    RESUBSCRIBE: 'This contact has resubscribed. You can send messages now.',
    LOCK_USER: 'Are you sure you want to lock the user?',
    UNLOCK_USER: 'Are you sure you want to unlock the user?',
    DELETE_USER: 'Are you sure you want to delete this user?',
    RE_INVITE_USER: 'Are you sure you want to re-invite this user?',
    USER_NOT_ACTIVE_YET: " User has not Logged In yet !!!",
    PASSWORD_REQUIREMENTS: '* Your password must contain the following:',
    PASSWORD_REQUIREMENT_ONE: '1. Should be between 8 to 20 characters',
    PASSWORD_REQUIREMENT_TWO: '2. At least 1 letter and at least 1 number or symbol',
    MEDIA_LIMIT: 'The file you are trying to send exceeds the 2 MB attachment limit. Try to compress it and send again.',
    CONVERSATION_BLOCKED: '',
    DELETE_FEATURE: 'Are you sure you want to delete this feature?',
    DISABLE_FEATURE: 'Are you sure you want to disable this feature?',
    ENABLE_FEATURE: 'Are you sure you want to enable this feature?',
    DELETE_PLAN: 'Are you sure you want to delete this Plan?',
    DISABLE_PLAN: 'Are you sure you want to disable this Plan?',
    ENABLE_PLAN: 'Are you sure you want to enable this Plan?',
    UNHIDE_PLAN: 'Are you sure you want to unhide this Plan?',
    HIDE_PLAN: 'Are you sure you want to hide this Plan?',
    DELETE_ADDON: 'Are you sure you want to delete this Add-On?',
    DISABLE_ADDON: 'Are you sure you want to disable this Add-On?',
    ENABLE_ADDON: 'Are you sure you want to enable this Add-On?',
    UNHIDE_ADDON: 'Are you sure you want to unhide this Add-On?',
    HIDE_ADDON: 'Are you sure you want to hide this Add-On?',
    GROUP_MESSAGE_DEFAULT_TAG: ' Reply STOP to opt-out',
    TEAMS_FACTOR_DISPLAY: 'Are you sure you want to turn this off? Your account will be protected with only password.',
    LANDLINE_TEXTING_TEXT: "You can send and receive texts from your existing landline number through Falcon SMS. You don't need to change your contact information anymore for texting.",
    BRAND_NEW_NUMBER_TEXT: "Don't have an existing line? No worries! We will provide a brand new number especially for you for free! You can then text anyone within your country using this number.",
    PORT_EXISTING_NUMBER_TEXT: 'You can port and text anyone with your existing number through Falcon SMS. Our simple and affordable plan will help you to port your number within minutes!',
    PHONE_NUMBER_SUCCESS_MESSAGE: "Your number has been successfully! added to this account!",
    NOCURRENTPLAN: "There is no current plan for this account.",
    PROVIDER_ENABLE: "Are you sure you want to enable this provider?",
    PROVIDER_DISABLE: "Are you sure you want to disable this provider?",
    BRAND_NEW_NUMBER_ENABLE: "Are you sure you want to enable this brand new number?",
    BRAND_NEW_NUMBER_DISABLE: "Are you sure you want to disable this brand new number?",
    BAND_NEW_NUMBER_DELETE: "Are you sure you want to delete this brand new number?",
    REPORT_ISSUE_DETAILS: "Attach screenshot/s of the issue faced. Image file only.",
    NO_CONTACTS_MATCH: "No matching contacts.",
    OVER_QUOTA_TEXT: "This amount will be added to the next billing cycle",
    MESSAGE_SEND_TOOL_TIP: "Ctrl+Enter to send message",
    PAYMENT_SUCCESSFUL: "Payment Successful!",
    PAYMENT_FAILED: "Payment Failed!",
    PAYMENT_AWAITED: "Payment Awaited",
    PAYMENT_AWAITED_NOTE: "We are waiting for the payment processor to confirm payment. Your payment confirmation will appear once your payment is received.",
    PAYMENT_AWAITED_FOOT_NOTE: "If you get tired of waiting, you can go back to plans and we will email you at your payment email address about the status of payment.",
    CANCEL_SUBSCRIPTION: "Are you sure you want to cancel the subscription?",
    LANDLINE_TEXTING: "Landline Texting",
    TOLL_FREE_TEXTING: "Toll free Texting",
    MS_TEAMS_TEXTING: "MS Teams Texting",
    VOIP_TEXTING: "VOIP Texting",
    SCHEDULED_MESSAGE_DELETE: "Are you sure you want to delete this Scheduled Message?",
    SCHEDULED_MESSAGE_RESUME: "Are you sure you want to resume this scheduled message?",
    SCHEDULED_MESSAGE_STOP: "Are you sure you want to stop this scheduled message?",
    NOTIFICATION_ENABLE_MESSAGE: "Are you sure you want to enable notifications?\n Please note that you will need to enable notifications in Windows settings as well as browser.",
    NOTIFICATION_DISABLE_MESSAGE: "Are you sure you want to disable notifications?\n Please note that you will not receive notification prompt when a message is received.",
    VOICE_VERIFICATION_ENABLE_MESSAGE: "Are you sure you want to enable Voice verification?",
    VOICE_VERIFICATION_DISABLE_MESSAGE: "Are you sure you want to disabe Voice verification?",
    INCORRECT_EMAIL_ADDRESS: "You have entered an incorrect email address. Please try again.",
    DID_NOT_RECEIVE: "Did not receive? ",
    INVALID_OTP_TRY_AGAIN: "Invalid OTP. Please try again",
    INVALID_OTP_RESEND_AGAIN: "Invalid OTP. Please try again or click Resend OTP",
    PASSWORD_RESET_SUCCESSFUL: "Password reset successful!",
    TAKE_SOME_TIME: "This may take sometime. You can also come back once the",
    REGISTRATION_COMPLETE: "registration is complete.",
    REGISTRATION_SUCCESSFULL: "Registration successful!",
    WAITING_FOR_REGISTERING_NUMBER: "We are registering your number!",
    ADDRESS: "Address",
    SAME_AS_ORGANIZATION: "Same as Organization",
    COPY_LINK_FOR_CUSTOMER: "Copy link and share with your customer",
    COPY_PAYMENT_LINK: "COPY PAYMENT LINK",
    PAYMENT_LINK: "A Payment link has been created",
    CONTACT_FALKON_SMS: "If you have any questions, contact Falkon SMS at ",
    SHARE_FEEDBACK: "Please share your feedback which will help us to improve your experience or contact us at ",
    ADD_PHONE_MOVING_AWAY_CONFIRMATION: "Are you sure you want to leave, you will lose data if you continue?",
    IMPORT_EXCEL_FILE: "To import contacts, upload an XLSX or CSV file.",
    IMPORT_OUTLOOK: "To import contacts, connect your outlook account",
    WRONG_TEMPLATE_ERROR: "Error - You are either trying to upload contacts using wrong template or the file contains incorrect data. Please refer to sample template file.",
    SAMPLE_TEMPLATE_FILE: "sample template file.",
    DOWNLOAD_SAMPLE_TEMPLATE: "Download contacts sample spreadsheet:",
    ALREADY_HAVE_AN_ACCOUNT: "Already have an account with us?",
    ADD_PHONE_NUMBER_VOICE_OTP_ERROR: "You have reached maximum attempts. Please try again after sometime",
    ORDER_SUMMARY: "Order summary",
    PROCESSED_SUCCESSFULLY: "processed successfully!",
    TRANSFER_COMPLETED: "Transfer completed!",
    THANK_YOU_FOR_PAYMENT: "Thank you. Your payment has been",
    SOMETHING_WENT_WRONG: "Something went wrong. Please",
    TRY_AGAIN: "try again.",
    CANCEL_SUBSCRIPTION_CONFIRMATION: "Are you sure you want to cancel current subscription? Please note that, all the recurring add-ons connected to this plan will not be renewed as well from next billing cycle",
    CANCEL_ADDON_CONFIRMATION: "Are you sure you want to cancel this add-on subscription? Please note that, this add-on will not be renewed from next billing cycle",
    BLOCKED_CONV: "You have blocked this contact",
    TAKE_SOME_TIME_REGISTRATION_COMPLETE: "This may take sometime. You can also come back once the registration is complete.",
    CLICK_TO_REFRESH_PLANS: "Click to refresh plans",
    FORGOT_YOUR_PASSWORD: "Forgot your password?",
    DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
    MANAGE_YOUR_WORKFLOW: "Manage your workflow!",
    START_MESSAGING_TODAY: "Send and receive messages using Toll-Free, Landline, VoIP or MS Teams direct routing number today!",
    CREDITS_ARE_TOO_LOW: "Your 1-1 credits are too low. To continue receiving messages, ",
    GROUP_CREDITS_ARE_TOO_LOW: "Your group credits are too low. To continue receiving messages, ",
    NO_ADDONS_AVAILABLE: "No add-ons available for your current subscription!",
    NO_PLAN_AVAILABLE: "No plans available for your product!",
    NOT_AVAILABLE: "N/A",
    AVAILABLE: "Available",
    RELEASE_ALERT_DELETE: "Are you sure you want to delete this Alert?",
    OFFLINE_ALERT_TEXT: "It looks you're not connected to the internet. Please check the connection.",
    MASS_ACTION_TEXT: "Are you sure you want to <action> the selected <name>?",
    START_YOUR_JOURNEY: "Start your Journey",
    PHONE_REGISTRATION_COMPLETE: " number registered successfully! please recharge to start using this number for texting.",
    TRANSFER_CONFIRMTAION_NOTE: "Are you sure you want to transfer <Amount> credits from \"<Soruce_Phone_Name>\" to \"<Target_Phone_Name>\"?",
    SET_TIME_ZONE: "Set time zone automatically",
    DEFAULT_TIME_ZONE: "Select your timezone to display all your messages in the time that matches your requirement",
    SELECT_PHONENUMBER: "Select the PhoneNumbers for which the report will be generated",
    SELECT_FREQUENCY: "Select the report frequency",
    SELECT_EMAIL: "Enter the email addresses to which the report will be sent",
    AUTO_ALERT_KEYWORD_DELETE: "Are you sure you want to delete this keyword?",
    AUTO_ALERT_KEYWORD_DELETE_ONE: "Deleting this keyword will impact '<Name>' rule. Are you sure you want to delete this keyword?",
    AUTO_ALERT_KEYWORD_DELETE_TWO: "Deleting this keyword will impact '<Name>', '<Name>' rules. Are you sure you want to delete this keyword?",
    AUTO_ALERT_KEYWORD_DELETE_MANY: "Deleting this keyword will impact '<Name>', '<Name>' +<count> rules. Are you sure you want to delete this keyword?",
    AUTO_ALERT_KEYWORD_ENABLE: "Are you sure you want to enable this Keyword?",
    AUTO_ALERT_KEYWORD_DISABLE: "Are you sure you want to disable this keyword?",
    AUTO_ALERT_KEYWORD_DISABLE_ONE: "Disabling this keyword will impact '<Name>' rule. Are you sure you want to disable this keyword?",
    AUTO_ALERT_KEYWORD_DISABLE_TWO: "Disabling this keyword will impact '<Name>', '<Name>' rules. Are you sure you want to disable this keyword?",
    AUTO_ALERT_KEYWORD_DISABLE_MANY: "Disabling this keyword will impact '<Name>', '<Name>' +<count> rules. Are you sure you want to disable this keyword?",
    AUTO_ALERT_RULE_RESUME: "Are you sure you want to resume this Rule?",
    AUTO_ALERT_RULE_START: "Are you sure you want to start this Rule?",
    AUTO_ALERT_RULE_STOP: "Are you sure you want to stop this Rule?",
    AUTO_ALERT_RULE_DELETE: "Are you sure you want to delete this Rule?",
    TRIGGER_RULE_ONLY_ONCE: "Trigger rule only once to new and unique number",
    ALERT_DIALG_TEXT: "An alert is already scheduled. Please stop it or delete it to add new alert.",
    TEMPLATE_DELETE: 'Are you sure you want to delete selected template?',
    AUTOMATION_RULES_ASSIGNED_PRIVATE: "Automation rules assigned to <PhoneNumber> number are listed here.",
    AUTOMATION_RULES_ASSIGNED_GLOBAL: "Automation rules created by you (<Username>) are listed here.",
    TEMPLATES_ASSIGNED_GLOBAL: "Everyone in the organization can view and use this template.",
    TEMPLATES_RULES_ASSIGNED_PRIVATE: "Only you can view and use this template.",
    TEMPLATES_RULES_PHONE_NUMBER: "Everyone linked with this phonenumber can view and use this template.",
    SCHEDULE_MESSAGE_RESTRICTION: "Scheduling messages is possible for only one group or individual contacts at a time.",
    AUTOMATION_RULES_DRAG_AND_DROP_PRIORITY: "Drag and drop to reorder automation rules. If a customers message matches more than one automation rule criteria, they will only get the reply with highest priority",
    CUSTOM_SIGNATURE: "Custom Signature",
    SIGNATURE_APPER: "Make my signature appear for all the messages",
    EDIT_SIGNATURE: "Edit Signature",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    ORGANIZATION: "Organization",
    PHONE_NUMBER: "Phone Number",
    EMAIL: "Email",
    ROLE: "Role",
    EDIT_CUSTOM_SIGNATURE: "Edit custom signature that can be added to your messages",
    LOW_CREDIT_MESSAGE: "Set the minimum credit number at which you want to receive an alert",
    AUTOMATICALLY_RECEIVE_NOTIFICATION: "You will automatically receive notifications via SMS or email, or both",
    AUTOMATICALLY_APPEND_SIGNATURE: " Automatically append signature to all outgoing messages",
    CUSTOM_TAGS: "Custom tags",
    CONFIRM_DOWNLOAD: "Are you sure you want to download this",
    CONFIRM_REDIRECT: "​You will be redirected out of Microsoft Teams",
    FREE: "Free",
    NOTIFICATION_DISABLE_MESSAGE_TEAMS: "Notifications are disabled in the FalkonSMS teams application.\n We are currently working on it.",
    GROUP_CHAT_BUTTON_INFO: "This name is shared with everyone in the group",
    GROUP_CHAT_BUTTON_INFO_2: "Shared Chat in the Group Chat",
    GROUP_CHAT_TO_BUTTON_INFO: "Group Chat allows multiple people to actively participate in a shared conversation. Group chat members can send and receive messages. These messages are visible to all the group members and are usually exchanged in real time. Atmost we can add upto 9 contacts in group chat",
    CAMPAIGN_CHAT_TO_BUTTON_INFO: "Send individual SMS messages to multiple recipients privately, ensuring replies appear in separate conversations for a more personalized experience.Only the falkon sms phone number users can see the campaign name",
    SWITCH_PHONE_NUMBER: "Would you like to switch Phone Number",
    DELETE_CONTACTS_WITH_GROUP: "Enabling this option will permenantly delete the contacts present in this group.",
    NOTE_DELETE_CONTACTS_WITH_GROUP: "Note: Contacts present in multiple groups will be ommitted from deletion.",
    NOTE_FOR_NOT_DELETED_CONTACTS: "While deleting the '<GroupName>' along with contacts, we have excluded the following contacts as they are associated with multiple groups.\n If you still want to delete those contacts, please delete them manually from contacts section.",
    SECURE_FILE: "Are you sure you want to delete this secure file link?",
    SECURE_FILE_YOU_DONT_HAVE_ACCESS_ERROR: "The sender has not granted you permission to download the file.",
    SECURE_FILE_YOU_DONT_HAVE_ACCESS_CONTACT_SENDER_ERROR: "Please check your email address or contact the sender for assistance.",
    FILE_SECURED_BY_FALKONSMS: "File Secured by FalkonSMS",
    FILE_DOWNLOAD_SUCCESS: "File downloaded successfully",
    CONFIRM_REMOVE_SECUREFILE_ACCESS: "Are you sure you want to remove access?",
    AUTOMATE_CREDIT_TRANSFER: "Auto Credit Transfer",
    MANUAL_CREDIT_TRANSFER: "Manual Credit Transfer",
    AUTO_CREDIT_TRANSFER_TEXT: "Automatically transfer credits between phone numbers when a recipient's balance falls below a set threshold, ensuring continuous messaging without manual intervention.",
    MANUAL_CREDIT_TRANSFER_TEXT: "Manually transfer credits between phone numbers as needed. Select the source, recipients, and the amount of credits to transfer.",
    CREDIT_LOGS_TEXT: "View monthly credit logs to track the number of credits assigned and used. Use the search filter to select and analyze data by month for better credit management.",
    MESSAGE_LOGS_TEXT: "Access your complete messaging history with Message Logs. Use filters to find specific conversations and streamline your messaging history analysis. Up to 10,000 records can be displayed. To view all records, please export the data.",
    TEAMS_LINK: "Select which Teams and Falkon SMS inbox to link.",
    RESPOND_FROM_TEAMS: "All text messages that come in to the inbox will displayed in the Teams Channel and you can respond directly from Teams.",
    SLACK_LINK: "Select a Falkon SMS inbox to link with Slack Channel.",
    RESPOND_FROM_SLACK: "All text messages that come in to the inbox will displayed in the Slack Channel and you can respond directly from Slack."
}

export const buttonLabels = {
    BACK_TO_LOGIN: "Back To Login",
    BACK_TO_SIGN_UP: "Back To Sign-up",
    BACK_TO_PLANS: "Back To Plans",
    CONTINUE_TO_LOGIN: "Continue To Login",
    FORGOT_PASSWORD: "Forgot Password",
    BACK_TO_CONV_TEAMS: "Go back to conversations",
    SHOP_NOW: 'Shop Now',
    BUY_NOW: 'Buy Now',
    ADD_GROUP: 'Add Group',
    ADD_ORGANIZATION: 'Add Organization',
    ADD_CONTACT: 'Add Contact',
    ADD_CONTACTS: 'Add Contacts',
    ADD_TO_CONTACTS: 'Add to Contacts',
    DONE: 'Done',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    UPDATE: "Update",
    BLOCK: 'Block',
    UNBLOCK: 'Unblock',
    ADD_PHONENUMBER: 'Add Phone Number',
    ADD_TIER: "Add Plan",
    UPDATE_TIER: "Update Plan",
    CONFIRM_PLAN: "Confirm",
    ADD_NEW_TIER: "Create New Plan",
    ADD_NEW_ADDON_PLAN: "Create New Add-On",
    EDIT_ADDON_PLAN: "Edit Add-On Plan",
    UPDATE_ADD_ON_PLAN: "Update Add-On Plan",
    DUPLICATE_ADDON_PLAN: "Duplicate Add-On Plan",
    CONFIRM_ADDON_NOW: 'Confirm',
    DUPLICATE_TIER: "Duplicate Plan",
    ADD_USER: 'Add User',
    SUBMIT: 'Submit',
    SAVE: 'Save',
    TURN_OFF: 'Turn Off',
    IMPORT_CONTACTS: 'Import Contacts',
    BACK_TO_HOME: 'Go back to home page',
    ADD_PROVIDER: 'Add Provider',
    ADD_NEW_NUMBER: 'Add New Number',
    IMPORT_ALL_RECORDS: 'Import All Records',
    IMPORT_CORRECT_RECORDS_ONLY: "Import Correct Records Only",
    IMPORT: 'Import',
    ADD: "Add",
    ADD_PLUS: "Add+",
    NEXT: 'Next',
    OK: 'OK',
    REFRESH: 'Refresh',
    TERMS_OF_SERVICE: 'Terms of Service',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIE_POLICY: 'Cookie Policy',
    ACCEPTABLE_USE_POLICY: 'Acceptable Use Policy',
    REGISTER_ACCOUNT: 'Register Account',
    APPLY: 'Apply',
    RESET: 'Reset',
    PRINT: "Print",
    GO_TO_PLANS: "Go to Plans",
    TRY_AGAIN: "Try Again",
    BACK: "Back",
    DELETE_MESSAGE: "Delete message",
    DOWNLOAD_CHAT: "Download chat",
    DOWNLOAD: "Download",
    ADD_SCHEDULED_MESSAGE: "Add Scheduled Message",
    SIGN_UP: "Sign Up",
    CALL_NOW: "Call Now",
    RESET_PASSWORD: "Reset Password",
    VERIFY_OTP: "Verify OTP",
    ATTACH: "Attach",
    GO_TO_HOME_PAGE: "Go to Home Page",
    RESEND: "Resend",
    GO_TO_PHONE_NUMBERS: "Go to Phone Numbers",
    UPLOAD_FILE: "Upload File",
    HERE: "here",
    XLSX: "XLSX",
    CSV: "CSV",
    SIGN_IN: "Sign In",
    PAY_NOW: "Pay Now",
    OKAY: "Okay",
    DUPLICATE: "Duplicate",
    ADD_KEYWORD: "Add Keyword",
    ADD_ALERT: "Add Alert",
    ADD_RULE: "Add Rule",
    PAUSE: "Pause",
    RESUME: "Resume",
    TRANSFER: "Transfer",
    GO_TO_LOGS: "Go to Logs",
    START: "Start",
    STOP: "Stop",
    ADD_NEW_TEMPLATE: "Add Template",
    UNSTOP: "Unstop",
    SHARE: "Share",
    DOWNLOAD_CONTACTS: "Download Contacts",
    HELP: "Help",
    ADD_NEW_MESSAGE: "New Message",
    ADD_KAFKA_CLUSTER: "Add Kafka Cluster",
    UPLOAD: "Upload",
    COPY: "Copy",
    COPIED: "Copied",
    REMOVE_ACCESS: "Remove access",
    VERIFY_DOWNLOAD: "Verify & Download",
    BACK_TO_VERIFY_EMAIL: "Back to Verify email",
    ADD_EMAIL: "Add Email",
    CLEAR: "Clear",
    SEARCH: "Search",
    EXPORT: "Export",
    VIEW_EXPORT: "View export history",
    CONFIRM: "Confirm",
    GO_TO_RECHARGE: "Go to ReCharge",
    ADD_NEW: "Add New",
    SETUP: "Setup",
    REQUEST_CONSENT: "Request Admin Consent",
    DISCONNECT: "Disconnect",
    LINK_CHANNEL: "Link Channel",
    LINK_PHONENMBER: "Link Phonenumber",
    TEST_INTEGRATION: "Test Integration",
    CONNECT_CLIO: "Connect to Clio",
    SYNC_ALL_CONTACTS: "Sync all Contacts",
    CONNECT_HUBSPOT: "Connect to HubSpot",
    INTEGRATE: "Integrate"

}

export const tenureTexts = {
    MONTH: 'Monthly',
    QUARTER: 'Quarter',
    SEMI_ANNUAL: 'Half Year',
    ANNUAL: 'Annual',
    UNLIMITED: 'Unlimited',
}

export const creditTypeTexts = {
    CREDIT: 'Credit',
    CREDITS: 'Credits',
    GROUP_TEXTING: 'Group Texting',
    USER: 'User',
    USERS: 'Users',
    PLAN_CREDITS_NORMAL: "Plan credits: 1-1",
    PLAN_CREDITS_GROUP: "Plan credits: Group",
    ADDON_CREDITS_NORMAL: "Add-on credits: 1-1",
    ADDON_CREDITS_GROUP: "Add-on credits: Group"
}

export const creditLabels = {
    UNLIMITED: 'Unlimited',
    CREDITS_MONTH: "{value} Credits' per month",
    GROUP_TEXTING: 'Group Texting - {value} credits',
    GROUP_TEXTING_NOT_AVAILABLE: 'Group Texting - N/A',
    USERS: 'Upto {value} Users',
    USER: 'One User',
    USER_NOT_AVAILABLE: 'Users - N/A',
}

export const monetaryUnitText = {
    CENT: 'Cent',
    CENTS: 'Cents',
    DOLLAR: 'Dollar',
    DOLLARS: 'Dollars',
}
export const pageTitles = {
    Organizations: 'Organizations',
    GROUPS: 'Groups(Campaign)',
    TIERS: 'Tiers',
    CONVERSATIONS: 'Conversations',
    DASHBOARD: 'Dashboard',
    PHONENUMBERS: 'Phone Numbers',
    USERS: 'Users',
    FEATURES: 'Features',
    PLAN: 'Plan',
    PLANS: 'Plans',
    ADDON: 'Add-On',
    ADDONS: 'Add-Ons',
    PLANS_HISTORY: 'Plans History',
    CONTACTS: 'Contacts',
    CONTACT_INFO: 'Contact Info',
    GROUP_CHAT_INFORMATION: "Group Chat Information",
    CAMPAGIN_CHAT_INFORMATION: "Group Broadcast Info",
    PROVIDERS: 'Providers',
    BRAND_NEW_NUMBER: 'Brand New Numbers',
    PRICING: 'Pricing',
    PAYMENT_HISTORY: 'Payment History',
    SCHEDULED_MESSAGES: 'Scheduled Messages',
    CONTACT_US: "Contact Us",
    UPDATE_PASSWORD: "Update Password",
    SIGN_IN: "Sign In to Falkon SMS",
    PAGE_NOT_FOUND: "Page Not Found",
    PROFILE: "Profile",
    EDIT_PROFILE: "Edit Profile",
    AUTOMATION_RULES_KEYWORDS: "Keywords",
    ALERTS: "Alerts",
    TRANSFER_CREDITS: "Transfer Credits",
    DOCUMENT_TITLE: "Falkon SMS",
    TRANSFER_LOGS: "Transfer and Logs",
    SETTINGS: "Settings",
    AUTOMATION_RULES_LOGS: "Logs",
    AUTOMATION_RULES: "Automation Rules",
    CHANGE_PASSOWRD: "Change Password",
    TRANSFERS: "Manual Credit Transfer",
    AUTOMATE_TRANSFER: "Auto Credit Transfer",
    MANUAL_TRANSFER: "Manual Transfer",
    AUTO_TRANSFER: "Auto Transfer",
    LOGS: "Logs",
    TEMPLATES: "Templates",
    RULES_FOR_THIS_NUMBER: "Rules for this number",
    CREATED_BY_ME: "Created by me",
    NEW_MESSAGE: "New Message",
    REPORTS: "Schedule-Broadcast Logs",
    EXPORT_REPORTS: "Export History",

  
    REPORT: "Report",
    GROUP_INFO: "Group Info",
    SWITCH_NUMBER: "Switch Phone Number",
    SECURE_FILES: "Secure Files",
    Links: "Links",
    CreditLog: "Credit Logs",
    MessageLogs: "Message Logs",
    INTEGRATIIONS: "Integrations",
    MS_TEAMS_SETUP: "MS Teams Setup",
    MS_TEAMS_ACTIVITY_FEED_SETUP: "MS Teams Activity Feed Setup",
    CLIO: "Clio",
    HUBSPOT:"HubSpot",
    SLACK:"Slack",
}

export const emptyLabels = {
    GROUPS: 'There are no groups available yet!',
    GROUP_CONTACTS: 'There are no contacts in this group yet!',
    CONTACTS: 'There are no contacts available yet!',
    USERS: 'There are no users available yet!',
    PLANS: 'There are no Plans available yet!',
    ORGANIZATION: 'There are no organizations available yet!',
    PHONE_NUMBER: 'There are no PhoneNumbers available yet!',
    TRANSACTION_HISTORY: 'There are no transactions available yet!',
    PROVIDERS: 'There are no providers available yet!',
    BRAND_NEW_NUMBERS: 'There are no brand new numbers available yet!',
    FEATURES: 'There are no features available yet!',
    SCHEDULED_MESSAGES: 'There are no scheduled messages available yet!',
    BLOCKED_CONTACTS: " There are no blocked contacts to display",
    KEYWORDS: 'There are no keywords available yet!',
    LOGS: 'There are no logs available yet!',
    CREDITLOGS: 'Access to organization logs requires a recharge of credits. Please recharge to view logs and utilize this feature.',
    ALERTS: " There are no alerts to display",
    RULES: 'There are no rules available yet!',
    TEMPLATES: "There are no templates available yet!",
    REPORTS: "There are no reports available yet!",
    CREDITS:"There are no credits log available yet",
    MSG_LOGS:"There are no message logs available yet",
    TIERS: "There are no Tiers available yet!",
    SecureFiles: "There are no secure files available yet!",
    CHANNELS: "Message received in an inbox will appear in the teams channel you specify. Channel members can reply to the message from Teams."
}

export const validationsMessages = {
    GROUP_NAME_REQUIRED: 'Group Name cannot be blank',
    GROUP_NAME_MAX_LENGTH: 'Group Name should not exceed 30 characters',
    TRANSFER_CREDITS_AMOUNT_INVALID: "Credit amount is invalid",
    TRANSFER_CREDITS_INSUFFICIENT: "You do not have sufficient credits to transfer",
    TRANSFER_CREDITS_FROM_REQUIRED: "From account cannot be blank",
    TRANSFER_CREDITS_TO_REQUIRED: "To account cannot be blank",
    TRANSFER_CREDITS_AMOUNT_REQUIRED: "Credit amount cannot be blank",
    TRANSFER_CREDITS_TYPE_REQUIRED: "Credit type cannot be blank",
    SHARE_GROUP_NAME_MAX_LENGTH: "To share the group, group name should not exceed 21 characters. Please edit the group name"
}

export const typeLabels = {
    TYPE_ZERO: 'Main Feature',
    TYPE_ONE: 'Additional Feature',
    TYPE_TWO: 'Additional Purchase',
}
export const PlansHeaders = {
    ADD_ONS: 'Add Ons',
    BRAND_NEW_NUMBER: 'Brand New Number',
    EDIT: 'Edit',
    ADD_PHONE_NUMBER: 'Add Phone Number',
    LANDLINE_NUMBER: 'Landline Number',
    PORT_EXISTING_NUMBER: 'Port Existing Number',
    SUCCESS: 'Success',
    CONFIRM: 'Confirm'
}

export const PhoneNumbersTypes = {
    LANDLINE_TEXTING: 'Landline Texting',
    BRAND_NEW_NUMBER: 'Brand New Number',
    PORT_EXISTING_NUMBER: 'Port Existing Number',
}

export const DialogHeaders = {
    CURRENT_PLAN_DETAILS: "Current Plan Details",
    TALK_WITH_US: "Talk with us!",
    PLAN_DETAILS: "Plan Details",
    EDIT_PROVIDER: "Edit Provider",
    ADD_PROVIDER: "Add Provider",
    IMPORT_CONTACTS: "Import Contacts",
    ADD_GROUP: "Add New Group",
    ADD_KEYWORD: "Add Keyword",
    ADD_RULE: "Add Rule",
    EDIT_RULE: "Edit Rule",
    START_RULE: "Start Rule",
    EDIT_KEYWORD: "Edit Keyword",
    ADD_TEMPLATE: "Add Template",
    EDIT_TEMPLATE: "Edit Template",
    DUPLICATE_TEMPLATE: "Duplicate Template",
    CREATE_LINK: "Link to '<FileName>' created",
    MANAGE_ACCESS: "Manage Access",
    UPLOAD: "Upload File"
}

export const ToasterTexts = {
    CONTACT_ADD_SUCCESS: "Contact '<Name>' added successfully!",
    CONTACT_EDIT_SUCCESS: "Contact '<Name>' updated successfully!",
    CONTACT_BLOCK_SUCCESS: "Contact '<Name>' has been blocked successfully!",
    CONTACT_UNBLOCK_SUCCESS: "Contact '<Name>' has been unblocked successfully!",
    CONTACTS_UNBLOCK_SUCCESS: "Contact unblocked successfully!",
    UNBLOCKED_CONTACTS_SUCCCESS: "Contacts unblocked successfully!",
    CONTACT_DELETE_SUCCESS: "Contact '<Name>' deleted successfully!",
    CONTACT_DELETE_FAILED: "Delete contact '<Name>' failed",
    PHONE_NUMBER_EXISTS: "Phone Number '<number>' already exists.",
    PHONENUMBER_ADD_SUCCESS: "Phone Number '<Name>' added successfully!",
    PHONENUMBER_EDIT_SUCCESS: "Phone Number '<Name>' details updated successfully!",
    PHONENUMBER_DISABLE_SUCCESS: "Phone Number '<Name>' disabled successfully!",
    PHONENUMBER_DISABLE_FAIL: "Phone Number '<Name>' disabling failed",
    PHONENUMBER_ENABLE_SUCCESS: "Phone Number '<Name>' enabled successfully!",
    PHONENUMBER_ENABLE_FAIL: "Phone Number '<Name>' enabling successfully!",//recheck
    RECHARGE_SUCCESS: "Recharge successful!",
    NEW_NUMBER_ADD_SUCCESS: "Brand New Number '<Name>' added successfully!",//ignore
    NEW_NUMBER_EDIT_SUCCESS: "Brand New Number '<Name>' updated successfully!", //ignore
    NEW_NUMBER_DELETE_SUCCESS: "Brand New Number '<Name>' deleted successfully!", //ignore
    NEW_NUMBER_ENABLE_SUCCESS: "Brand New Number '<Name>' enabled successfully!",//ignore
    NEW_NUMBER_DISABLE_SUCCESS: "Brand New Number '<Name>' disabled successfully!",//ignore
    PROVIDER_ADD_SUCCESS: "Provider '<Name>' added successfully!", //ignore
    PROVIDER_EDIT_SUCCESS: "Provider '<Name>' updated successfully!", //ignore
    PROVIDER_ENABLE_SUCCESS: "Provider '<Name>' enabled successfully!",//ignore
    PROVIDER_DISABLE_SUCCESS: "Provider '<Name>' disabled successfully!",//ignore
    ORGANIZATION_ADD_SUCCESS: "Organization '<Name>' added successfully!",
    ORGANIZATION_EDIT_SUCCESS: "Organization '<Name>' updated successfully!",
    ORGANIZATION_DISABLE_SUCCESS: "Organization '<Name>' disabled successfully!",
    ORGANIZATION_ENABLE_SUCCESS: "Organization '<Name>' enabled successfully!",
    ORGANIZATION_ALREADY_EXIST: "Organization '<Name>' already exists",
    REPORT_ADD_SUCCES: "Report created successfully!",
    LOW_CREDIT_NUMBER: "Low credits updated successfully!",
    FILTERS_APPLIED: "Filters applied successfully!",
    USER_ADD_SUCCESS: "User '<Name>' added successfully!",
    USER_EDIT_SUCCESS: "User '<Name>' updated successfully!",
    USER_DELETE_SUCCESS: "User '<Name>' deleted successfully!",
    USER_LOCK_SUCCESS: "User '<Name>' locked successfully!",
    USER_UNLOCK_SUCCESS: "User '<Name>' unlocked successfully!",
    ISSUE_REPORT_SUCCESS: "Issue reported successfully! We will get back to you!",//ignore
    PRICING_UPDATE_SUCCESS: "Pricing of '<Name>' updated succcessfully",
    TIER_HIDE_SUCCESS: "Plan '<Name>' hide successfully!",
    TIER_UNHIDE_SUCCESS: "Plan '<Name>' unhide successfully!",
    TIER_DELETE_SUCCESS: "Plan '<Name>' deleted successfully!",
    TIER_EDIT_SUCCESS: "Plan '<Name>' updated successfully!",
    TIER_ADD_SUCCESS: "Plan '<Name>' added successfully!",
    TIER_DUPLICATE_SUCCESS: "Plan '<Name>' duplicated successfully!",
    FEATURE_DISABLE_SUCCESS: "Feature '<Name>' disabled successfully!",
    FEATURE_ENABLE_SUCCESS: "Feature '<Name>' enabled successfully!",
    PHONE_NUMBER_NAME_EDIT_SUCCESS: "Name '<Name>' updated successfully!", //will revisit further
    // MESSAGE_SENT_SUCCESS: "Message sent successfully!",
    CONTACT_ARCHIVE_SUCCESS: "Contact '<Name>' archived successfully!",
    CONTACT_UNARCHIVE_SUCCESS: "Contact '<Name>' unarchived successfully!",
    CONTACT_PIN_SUCCESS: "Contact '<Name>' pinned successfully!",
    CONTACT_UNPIN_SUCCESS: "Contact '<Name>' unpinned successfully!",
    ARCHIVE_SUCCESS: "Conversation archived successfully!",
    UNARCHIVE_SUCCESS: "Conversation unarchived successfully!",
    PIN_SUCCESS: "Conversation pinned successfully!",
    UNPIN_SUCCES: "Conversation unpinned successfully!",
    DELETE_SUCCESS: "Conversation deleted successfully!",
    DELETE_SUCCESS_CONVERSATIONS: "Conversations deleted successfully!",
    MARK_AS_READ_SUCCESS: "Conversation marked as read successfully!",
    MARK_AS_UNREAD_SUCCESS: "Conversation marked as unread successfully!",
    UNSUBSCRIBE_INFO: "has unsubscribed from your list", //recheck
    RESUBSCRIBE_INFO: "has resubscribed to your list",//recheck
    MESSAGE_DELETE_SUCCESS: "Message deleted successfully!",
    MESSAGES_DELETE_SUCCESS: "Messages deleted successfully!",
    PROFILE_EDIT_SUCCESS: "Profile updated successfully!",
    CHANGE_PASSWORD_SUCCESS: "Password updated successfully!",
    FILE_DOWNLOAD_SUCCESS: "File downloaded successfully!",
    MESSAGE_SEND_FAIL: "Message failed to send to '<Name>'",
    GROUP_ADD_SUCCESS: "Group '<Name>' added successfully",
    GROUP_EDIT_SUCCESS: "Group '<Name>' updated successfully",
    GROUP_DUPLICATE_SUCCESS: "Group '<Name>' duplicated successfully",
    GROUP_DUPLICATE_FAILED: "Group '<Name>' duplication failed",
    GROUP_NAME_MAX_LENGTH: " To duplicate, Group Name should not exceed 23 characters. Please edit the group name.",
    GROUP_DUPLICATE_NAME_TAKEN: "Group '<Name>' already exists",
    GROUP_EDIT_FAILED_MAX_CONTACTS_EXCEEDED: "A group can have maximum of <count> contacts",
    GROUP_DELETE_SUCCESS: "Group '<Name>' deleted successfully",
    GROUP_EDIT_CONTACTS_NULL: "There won't be any contacts in this group!",
    DOWNLOADING_CHAT: "Started Downloading '<Name>'s' chat...",
    DOWNLOADED_CHAT: "Chat downloaded successfully",
    RECHARGE_FAIL: "Recharge failed!",
    PHONE_NUMBER_SETTINGS_EDIT_SUCCESS: "<Name>'s settings updated successfully",
    ERROR: "An error occured!",
    ORG_TWO_FACTOR_AUTH_ON: "2-factor authentication is enabled for <Name>!",
    ORG_TWO_FACTOR_AUTH_OFF: "2-factor authentication is disabled for <Name>!",
    ORG_OUT_BOUND_TEAMS_ON: "Teams Outbound messages enabled for <Name>!",
    ORG_OUT_BOUND_TEAMS_OFF: "Teams Outbound messages is disabled for <Name>!",
    ORG_MANUAL_INVOICE_ON: "Manual Invoice is enabled for <Name>!",
    ORG_MANUAL_INVOICE_OFF: "Manual Invoice is disabled for <Name>!",
    NOTIFICATION_ON: "Notification is enabled!",
    NOTIFICATION_OFF: "Notification is disabled!",
    ACTIVITY_FEED_NOTIFICATION_ON: "Activity Feed Notification is enabled!",
    ACTIVITY_FEED_NOTIFICATION_OFF: "Activity Feed Notification is disabled!",
    VOICE_VERIFICATION_ON: "Voice verification is enabled!",
    VOICE_VERIFICATION_OFF: "Voice verification is disabled!",
    MUTE_CONVERSATION: "' <Name> ' muted successfully!",
    UNMUTE_CONVERSATION: "' <Name> ' unmuted successfully!",
    RESENDING_VOICE_OTP: "Resending Voice OTP",
    RESEND_VOICE_OTP_SUCCESS: "Voice OTP sent successfully",
    RESEND_VOICE_OTP_FAILURE: "Resending Voice OTP failed",
    INVAILD_VOICE_OTP: "Please enter correct OTP",
    VOICE_OTP_VERIFY_SUCCESS: "Voice verification completed",
    RESEND_OTP_SUCCESS: "OTP sent successfully",
    RESEND_OTP_FAILURE: "Resend OTP failed",
    SCHEDULED_MESSAGE_CREATED_SUCCESS: "Scheduled message added successfully!",
    SCHEDULED_MESSAGE_CREATED_FAILED: "Scheduled message could not be added!",
    SCHEDULED_MESSAGE_UPDATED_SUCCESS: "Scheduled message updated successfully!",
    SCHEDULED_MESSAGE_UPDATED_FAILED: "Scheduled message could not be updated!",
    SCHEDULED_MESSAGE_DELETE_SUCCESS: "Scheduled message for '<Name>' deleted successfully!",
    SCHEDULED_MESSAGE_DELETE_FAILED: "Scheduled message for '<Name>' could not be deleted!",
    SCHEDULED_MESSAGE_RESUME_SUCCESS: "Scheduled Message for '<Name>' resumed successfully!",
    SCHEDULED_MESSAGE_RESUME_FAILED: "Scheduled Message for '<Name>'could not be resumed!",
    SCHEDULED_MESSAGE_STOP_SUCCESS: "Scheduled Message for '<Name>' stopped successfully!",
    SCHEDULED_MESSAGE_STOP_FAILED: "Scheduled Message for '<Name>' could not be stopped!",
    SCHEDULED_MESSAGE_PASTDATETIME: "This schedule occurs in the past. Please change the schedule to a future date and time.",
    PASSOWRD_RESET_SUCCESS: "Password Reset was successful, Please login back!",
    PASSOWRD_RESET_FAILED: "Password Reset was unsuccessful, Please try again!",
    PHONE_NUMBER_NOT_VALID: "Phone Number '<number>' is not valid!",
    EMAIL_OTP_VERIFY_SUCCESS: "Email verification completed!",
    INVAILD_EMAIL_OTP: "Please enter correct email OTP",
    EXPIRED_EMAIL_OTP: "Email otp has been expired. Please try again",
    RESENDING_EMAIL_OTP: "Resending email OTP",
    RESEND_EMAIL_OTP_SUCCESS: "Email OTP sent successfully",
    RESEND_EMAIL_OTP_FAILURE: "Resending email OTP failed",
    NUMBER_OTP_VERIFY_SUCCESS: "Phone Number verification completed!",
    INVAILD_NUMBER_OTP: "Please enter correct Phone Number OTP",
    RESENDING_NUMBER_OTP: "Resending Voice OTP",
    RESEND_NUMBER_OTP_SUCCESS: "Voice OTP sent successfully",
    RESEND_NUMBER_OTP_FAILURE: "Resending Phone Number OTP failed",
    PHONE_NUMBER_NOT_VERIFIED: "Phone Number '<number>' is not verified!",
    EMAIL_OR_MOBILE_NUMBER_EXISTS: "Email or Mobile number already exists!",
    CANCELLING_SUBSCRIPTION: "Cancelling Subscription!",
    CANCEL_SUBSCRIPTION_SUCCESS: "Cancelled Subscription Successfully!",
    CANCELLING_ADDON: "Cancelling Addon!",
    CANCEL_ADDON_SUCCESS: "Cancelled Addon Successfully!",
    CANCEL_SUBSCRIPTION_FAILED: "Cancel Subscription Failed!",
    UNABLE_CANCEL_SUBSCRIPTION: "We are unable to cancel the Add-on subscription at the moment. Please contact our support team ",
    CANCEL_ADDON_FAILED: "Cancel Addon Failed!",
    MAXIMUM_PINNED_CONV: "You cannot pin more than <count> conversations!",
    SOMETHING_WENT_WRONG_ERROR: "Something went wrong!",
    ALERT_ADD_SUCCESS: "Alert added successfully!",
    ALERT_EDIT_SUCCESS: "Alert updated successfully!",
    ALERT_STOPPED_SUCCESS: "Alert '<Name>' stopped successfully!",
    ALERT_RESUME_SUCCESS: "Alert '<Name>' resumed successfully!",
    ALERT_DELETE_SUCCESS: "Alert notification '<Name>' deleted successfully!",
    ALERT_DELETE_FAILED: "Alert notification for '<Name>' could not be deleted!",
    MULTIPLE_USER_DELETE_SUCCESS: "Users deleted successfully!",
    MULTIPLE_USER_LOCK_SUCCESS: "Users locked successfully!",
    MULTIPLE_USER_UNLOCK_SUCCESS: "Users unlocked successfully!",
    MULTIPLE_SCHEDULED_MESSAGE_DELETE_SUCCESS: "Scheduled messages deleted successfully!",
    MULTIPLE_SCHEDULED_MESSAGE_RESUME_SUCCESS: "Scheduled messages resumed successfully!",
    MULTIPLE_SCHEDULED_MESSAGE_STOP_SUCCESS: "Scheduled message stopped successfully!",
    MULTIPLE_CONTACTS_UNBLOCK_SUCCESS: "Contacts unblocked successfully!",
    MULTIPLE_CONTACTS_BLOCK_SUCCESS: "Contacts blocked successfully!",
    MULTIPLE_CONTACT_DELETE_SUCCESS: "Contacts deleted successfully!",
    MULTIPLE_GROUP_DUPLICATE_SUCCESS: "Groups duplicated successfully",
    MULTIPLE_GROUP_DELETE_SUCCESS: "Groups deleted successfully",
    GROUPS_CREDIT_ERROR_MESSAGE: "You do not have the required group credits. You can add more credits by recharging with “Group Texting” add-on.",//
    CREDITS_PER_MONTH_ERROR_MESSAGE: "You do not have the required 1-1 credits. You can add more credits by recharging with “1-1 Texting” add-on.",//
    REINVITE_MESSAGE: "Mail has been sent to the user successfully!",
    SOMETHING_WENT_WRONG_MESSAGE: "something went wrong",
    IN_ACTIVE_PLAN: "There is no active plan available for this number. Please recharge.",
    USER_NOT_FOUND: "User not found",
    USER_ALREADY_LOGGED_IN: "User already logged in",
    FILE_SIZE_EXCEED_LIMIT: "File size should not exceed <size> MB",
    TIME_ZONE_MESSAGE: "Time zone updated successfully",
    KEYWORD_ADD_SUCCESS: "Keyword '<Name>' added successfully!",
    KEYWORD_UPDATE_SUCCESS: "Keyword '<Name>' updated successfully!",
    AUTO_ALERT_KEYWORD_DELETE_SUCCESS: "Keyword for '<Name>' deleted successfully!",
    AUTO_ALERT_KEYWORD_ALREADY_EXIST: "Keyword '<Name>' already exists ",
    AUTO_ALERT_KEYWORD_DELETE_FAILED: "Keyword for '<Name>' could not be deleted!",
    AUTO_ALERT_KEYWORD_ENABLE_SUCCESS: "Keyword for '<Name>' enabled successfully!",
    AUTO_ALERT_KEYWORD_DISABLE_SUCCESS: "Keyword for '<Name>' disabled successfully!",
    AUTO_ALERT_KEYWORD_ENABLE_FAILED: "Keyword for '<Name>'could not be enabled!",
    AUTO_ALERT_KEYWORD_DISABLE_FAILED: "Keyword for '<Name>' could not be paused!",
    AUTO_ALERT_RULE_DELETE_SUCCESS: "Rule '<Name>' deleted successfully!",
    AUTO_ALERT_RULE_RESUME_SUCCESS: "Rule '<Name>' resumed successfully!",
    AUTO_ALERT_RULE_START_SUCCESS: "Rule '<Name>' started successfully!",
    // AUTO_ALERT_RULE_PAUSE_SUCCESS: "Rule '<Name>' disabled successfully!",
    AUTO_ALERT_RULE_STOP_SUCCESS: "Rule '<Name>' stopped successfully!",
    AUTO_ALERT_RULE_ADD_SUCCESS: "Rule '<Name>' added successfully!",
    AUTO_ALERT_RULE_UPDATE_SUCCESS: "Rule '<Name>' updated successfully!",
    AUTO_ALERT_RULE_DELETE_FAILED: "Delete Rule '<Name>' failed",
    AUTO_ALERT_RULE_STOP_FAILED: "Rule '<Name>' could not be stopped!",
    AUTO_ALERT_RULE_START_FAILED: "Rule '<Name>' could not be started!",
    AUTO_ALERT_RULE_RESUME_FAILED: "Rule '<Name>' could not be resumed!",
    FILE_UPLOAD_FAILED: "\"<name>\" isn't supported for import",
    TRANSFER_FAILED_TO_NUMBER_NOT_ACTIVE: "<TO_PHONE_NAME> is not active",
    TEMPLATE_ADD_SUCCESS: "Template '<Name>' added successfully!",
    TEMPLATE_DUPLICATE_SUCCESS: "Template '<Name>' Duplicated successfully!",
    TEMPLATE_UPDATE_SUCCESS: "Template'<Name>' updated successfully!",
    TEMPLATE_DELETE_SUCCESS: "Template '<Name>' deleted successfully!",
    CONTACT_ADDING_FOR_SCHEDULE_MESSAGE: "Only contacts can be added at a time while sending a message",
    GROUP_ADDING_FOR_SCHEDULE_MESSAGE: "Only one group can be added at a time while sending a message",
    EXCEL_EXPORT_CONTACT_MESSAGE: "Contacts downloaded successfully",
    GROUP_SHARED_SUCCESSFULLY: "Group '<groupName>' shared successfully!",
    SHARED_RULE_SUCCESSFULLY: "Rule shared succesfully",
    CUSTOM_SIGNATURE: "Custom signature updated successfully",
    EXCEL_EXPORT_REPORT_MESSAGE: "Report downloaded successfully",
    START_DATE_END_DATE_BLANK: "Start date and end date cannot be blank",
    Text_File_Not_Supported: "Text file not supported",
    GROUP_BRODCAST_SENT_SUCCESSFULLY: "Group Broadcast is being sent. For delivery statistics, please check reports after a while.",
    CONTACT_SHARED_SUCCESSFULLY: "Contact shared successfully",
    NO_CONTACTS_TO_FETCH: "Oops! No valid contacts found in your outlook account.",
    SECURE_FILE_DELETE_SUCCESS: "file '<Name>' deleted successfully!",
    EDIT_GROUPCHAT_NAME_SUCCESS: "Edited groupchat name successfully",
    EDIT_CAMPAIGN_NAME_SUCCESS: "Edited Campaign name successfully",
    SWITCH_NUMBER: "Number switched successfully",
    CLIO_CONNECTION_SUCCESS: "Connected to clio",
    CLIO_DISCONNECT: "Clio disconnected",
    SYNC_ALL_CONTACTS_TO_CLIO: "All Contacts are synced successfully",
    CREATE_RULE: "CreditTransferRule Created Successfully",
    UPDATE_RULE: "CreditTransferRule Updated Successfully",
    DELETE_RULE: "CreditTransferRule Deleted Successfully",
    HUBSPOT_CONNECTION_SUCCESS:"Connected to Hubspot",
    HUBSPOT_DISCONNECT: "Hubspot disconnected",
    SYNC_ALL_CONTACTS_TO_HUBSPOT: "All Contacts are synced successfully",


}

export const RechargeStatusLabels = {
    NONE: "None",
    ONGOING: "Ongoing",
    UPCOMING: "Upcoming",
    COMPLETED: "Completed",
    FAILED: "Failed",
    ON_HOLD: "On-hold",
    CANCELLED: "Cancelled",
}
export const PhoneNumberStatusLabels: { [key: string]: string } = {
    NONE: "None",
    IN_PROGRESS: "In progress",
    PAYMENT_DUE: "Payment due",
    ACTIVE: "Active",
    INACTIVE: "Inactive"
}

export const ActionLabels = {
    EDIT: "Edit",
    CANCEL_SUBSCRIPTION: "Cancel Subscription",
    CANCEL_ADDON: "Cancel Addon",
    PLAN_DETAILS: "Plan Details",
    RECHARGE: "Recharge",
    MANUALPAYMENT: "ManualPayment",
    CURRENT_PLAN_DETAILS: "Current Plan Details",
    DISABLE: "Disable",
    ENABLE: "Enable",
    SETTINGS: "Settings",
    PLANS_HISTORY: "Plans History",
    DOWNLOAD_INVOICE: "Download Invoice",
    GROUP: "Group",
    DELETE: "Delete",
    SEND: "Send",
    DOWNLOAD: "Download",
    BLOCK: "Block",
    UNBLOCK: "Unblock",
    UNARCHIVE: "Unarchive",
    ARCHIVE: "Archive",
    PIN: "Pin",
    UNPIN: "Unpin",
    MARK_AS_READ: "Mark as Read",
    MARK_AS_UNREAD: "Mark as Unread",
    DOWNLOAD_CHAT: "Download Chat",
    SEND_MESSAGE: "Send Message",
    DUPLICATE: "Duplicate",
    TRANSACTION_HISTORY: "Transaction History",
    UNLOCK: "Unlock",
    LOCK: "Lock",
    CHOOSE_FILE: "Choose File",
    UPLOAD_PHOTO: "Upload Photo",
    CHANGE_PHOTO: "Change Photo",
    PROFILE: "Profile",
    CHANGE_PASSWORD: "Change Password",
    LOGOUT: "Logout",
    PAYMENT_HISTORY: 'Payment History',
    SELECT_FEATURE_TYPE: "Select Feature Type*",
    MAIN_FEATURE: "Main Feature",
    ADDITIONAL_FEATURE: "Additional Feature",
    ADDITIONAL_PURCHASES: "Additional Purchases",
    SELECT_FEATURE: "Select Feature",
    MUTE: "Mute",
    UNMUTE: "Unmute",
    RESUME: "Resume",
    STOP: "Stop",
    UNBLOCK_CONTACT: "UnBlock Contact",
    BLOCK_CONTACT: "Block Contact",
    EDIT_CONTACT: "Edit Contact",
    DELETE_CONTACT: "Delete Contact",
    REHOST: "Rehost",
    VIEW_DETAILS: "View details",
    REMOVE_PHOTO: "Remove Photo",
    REINVITE: "Re-invite",
    PAUSE: "Pause",
    USE_THIS_RULE: "Use this Rule",
    START: "Start",
    SHARE_THIS_GROUP: "Share this group",
    SHARE: "Share",
    SHARE_THIS_CONTACT: "Share this Contact",
    CLEAR_SELECTION: "",
    CLEAR_SELECTION_CONVERSATION: "Cancel",
    EDIT_GROUP_CHAT: "Edit Group chat",
    DELETE_CONVERSATION: "Delete Conversatiion",
    BRODCAST_MESSAGE: "Broadcast Message",
    VIEW_GROUP_INFO: "View Group Info",
    GET_LINK: "Get Link",
    ADD_CONTACT: "Add Contact",
    MANAGE_ACCESS: "Manage access",
    TEAMS_CHANNEL: "Teams Channel Setup",
    INTEGRATIIONS: "Integrations"
}

export const appLabels = {
    FALKON_SMS: "Falkon SMS",
    FALKON_NUMBER: "+1 (346) 284-7053",
    FALKON_EMAIL: "fs-support@falkonsystems.com",
    PRIVATE: "Rules for this number",
    PRIVATE_MOBILE: "Assigned",
    GLOBAL: "Created by me",
    GLOBAL_MOBILE: "Created"
}

export const SignupTitles = {
    SIGN_UP: "Sign Up",
    ORGANIZATION: "Organization",
    SELECT_PRODUCT: "Select Product *",
    SELECT_PLAN: "Select Plan",
    LANDLINE_VOIP_TEXTING: "Landline/ VoIP Texting",
    MS_TEAMS_CALLING: "MS Teams Calling & Texting",
    OTP_VERIFICATION: "OTP Verification",
    PAYMENT: "Payment",
    SUCCESS: "Success"
}
export const ScheduleMessageTypeTexts = {
    ONE_TIME: "One Time",
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
}

export const ScheduleMessageStatusText = {
    SCHEDULED: "Scheduled",
    SENT: "Sent",
    STOPPED: "Stopped"
}

export const ViewTextLabels = {
    GROUP_CHAT: "Group Chat",
    PHONE_NUMBER: "Phone Number",
    EMAIL_ID: "Email ID",
    GROUPS: "Groups",
    SECONDS: "Seconds",
    NEW_MESSAGE: "New Message",
    SEND_EMAIL: "Send email",
    SHOW_QR_CODE: "Show QR Code",
    DESCRIPTION: "Description",
    PRICE: "Price",
    CALL_AT: "call at",
    TRANSACTION_ID: "Transaction ID",
    PLANS: "Plans",
    INVOICE_ID: "Invoice ID",
    DATE: "Date",
    EMAIL_VERIFICATION: "Email Verification",
    PHONE_NUMBER_VERIFICATION: "Phone Number Verification",
    VERIFICATION: "Verification",
    YOUR_NUMBER: "Your Number:",
    DOWNLOAD_WINDOWS_APP: "Windows",
    DOWNLOAD_MACOS_APP: "MacOS",
    DOWNLOAD_PAYSTORE_APP: "Play Store",
    DOWNLOAD_APPSTORE_APP: "App Store",
    DOWNLOAD_TEAMSSTORE_APP: "Teams Store",
    DOWNLOAD_CHROMESTORE_APP: "Chrome Store",
    SIGN_UP: "Sign Up",
    VERSION: "Version",
    WRONG_PASSWORD_ATTEMPTS: 'Wrong Password Attempts',
    NOTIFICATION: 'Notifications',
    TWO_FACTOR_AUTHENTICATION: '2-factor authentication',
    VOICE_VERIFICATION: 'Voice Verification',
    BLOCKED: 'Blocked',
    UNSUBSCRIBED: 'Unsubscribed',
    ARCHIVED_CHATS: 'Archived Chats',
    UNREAD_MESSAGES: "Unread Messages",
    UNREAD_MESSAGE: "Unread Message",
    SIGN_IN: "Sign in",
    FROM: "From",
    TO: "To",
    CREDIT_AMOUNT: "Credit Amount",
    CREDIT_TYPE: "Credit Type",
    TIME_ZONE: "Time zone",
    PRIORITY: "Priority",
    CUSTOM_SIGNATURE: "Custom Signature",
    LOW_CREDIT_ALERT: "Low Credit Notification",
    SCHEDULED_REPORT: "Scheduled Report",
    CONTACT_US: "Contact Us",
    PHONE_NUMBERS: "Phone Numbers",
    ENABLE_OUTBOUND: "Enable Outbound in Teams Bot",
    MANUAL_INVOICE: "Enable Manual Invoice"
}
export const ImportContactsLabel = {
    UPLOAD_CONTACTS: "Upload Contacts",
    ADD_A_CONTACT: "Add a Contact",
    SELECT_A_GROUP: "Select a Group",
}

export const TextFieldLabels = {
    ORGANIZATION: "Organization *",
    INDUSTRY: "Industry *",
    HOUSE_NUMBER: "House Number *",
    STREET_NAME: "Street Name *",
    ZIPCODE: "Zipcode *",
    CITY: "City *",
    STATE: "State *",
    COUNTRY: "Country *",
    NAME: "Name *",
    PHONE_NUMBER: "Phone Number *",
    FIRST_NAME: "First Name *",
    LAST_NAME: "Last Name *",
    EMAIL: "Email *",
    MOBILE_NUMBER: "Mobile Number *",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    USER_NAME: "User Name *",
    PASSWORD: "Password *",
    ENTER_EMAIL_ID_FOR_RETRIEVING_YOUR_PASSWORD: "Enter email id for retrieving your password"
}
export const appEmail = {


}

export const ProductTypeLabel = {
    LANDLINE_TEXTING: "Landline",
    VOIP_TEXTING: "VoIP",
    MS_TEAMS_TEXTING: "MS Teams",
    TOLL_FREE_TEXTING: "Toll-Free",
}

export const UsersMassAction = {
    DELETE: "delete",
    LOCK: "lock",
    UNLOCK: "unlock",
    USER: "user",
}

export const ScheduledMessageMassAction = {
    DELETE: "delete",
    STOP: "stop",
    RESUME: "resume",
    SCHEDULE_MESSAGE: "schedule message",
}

export const ContactsMassAction = {
    DELETE: "delete",
    SEND: "send",
    UNBLOCK: "unblock",
    BLOCK: "block",
    CONTACT: "contact",
    SHARE: "share",
}

export const ConversationsMassAction = {
    DELETE: "delete",
    UNBLOCK: "unblock",
    BLOCK: "block",
    ARCHIVE: "archive",
    UNARCHIVE: "unarchive",
    CONVERSATION: "conversation",
}

export const GroupsMassAction = {
    DELETE: "delete",
    SEND: "send",
    DUPLICATE: "duplicate",
    GROUP: "group",
}

export const RolesLabel = {
    SYSTEM_ADMIN: "System Admin",
    ORGANIZATION_ADMIN: "Organization Admin",
    USER: "User"
}

export const WeekDaysLabel = {
    SUNDAY: "Sun",
    MONDAY: "Mon",
    TUESDAY: "Tue",
    WEDNESDAY: "Wed",
    THURSDAY: "Thu",
    FRIDAY: "Fri",
    SATURDAY: "Sat",
}

export const AutomationRulesLabel = {
    SENDERID: "00000000-0000-0000-0000-000000000000",
    AUTOMATED_REPLY: "Automated Reply",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    COMPLETED: "Completed",
    FAILED: "Failed",
    AUTO_REPLY: "Auto Reply",
    KEYWORD: "Keyword",
}

export const ContactTags = [
    { id: "FirstName", display: "First Name", length: 10, className: "contact" },
    { id: "PhoneNumber", display: "Phone Number", length: 17, className: "contact" },
    { id: "LastName", display: "Last Name", length: 10, className: "contact" },
    { id: "Email", display: "Email", length: 20, className: "contact" }
];

export const UserTags = [
    { id: "MyFirstName", display: "My First Name", length: 10, className: "user" },
    { id: "MyLastName", display: "My Last Name", length: 10, className: "user" },
    { id: "MyEmail", display: "My Email", length: 20, className: "user" },
    { id: "MyPhoneNumber", display: "My Phone Number", length: 17, className: "user" },
    { id: "MyCompanyName", display: "My Company Name", length: 15, className: "user" }
];

export const AppTags = [
    ...ContactTags,
    ...UserTags
];

export const Tags = [
    {
        catagory: "Contact",
        label: "Contact Details",
        tags: [...ContactTags]
    },
    {
        catagory: "user",
        label: "My Details",
        tags: [...UserTags]
    }

];

export const GridStatusLabelVariant = {
    Active: "active",
    Inactive: "inactive",
    Warning: "warning",
    Danger: "danger",
    Info: "info",
    Other: "other"
}

export const IntegrationLabels = {
    MICROSOFT_TEAMS: "Microsoft Teams",
    CLIO: "Clio",
    ACTIVITY_FEED: "Microsoft Teams Activity Feed Notification",
    HubSpot:"HubSpot",
    SLACK: "Slack",
}