export const WebRoutes = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    DASHBOARD: "/dashboard",
    PLANS: "/plans",
    RECHARGE: "/recharge",
    USERS: "/users",
    CONVERSATIONS: "/conversations",
    CONVERSATION_HISTORY: "/conversations/:id",
    SCHEDULED_MESSAGES: "/scheduled-messages",
    CONTACTS: "/contacts",
    IMPORT_CONTACTS: "/contacts/import-contacts",
    IMPORT_CONTACTS_EXCEL: "/contacts/import-contacts/excel",
    IMPORT_CONTACTS_OUTLOOK: "/contacts/import-contacts/outlook",
    GROUPS: "/groups",
    PROFILE: "/profile",
    SETTINGS: "/settings",
    BLOCKED: "/settings/blocked",
    UNSUBSCRIBE: "/settings/unsubscribe",
    SIGNATURE: "/settings/signature",
    LOW_CREDIT_NOTIFICATION: "/settings/low-credit-notification",
    TIMEZONE: "/settings/timezone",
    SCHEDULED_REPORT: "/settings/scheduledreport",
    CHANGE_PASSWORD: "/change-password",
    ADD_PHONE_NUMBER: "/add-phone-number",
    PAYMENT_STATUS: "/payment-status",
    REHOST: "/rehost",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD_NEW: "/reset-password",
    TWO_FACTOR_AUTHENTICATION: "/2fa",
    ORGANIZATIONS: "/organizations",
    PHONE_NUMBERS: "/phone-numbers",
    TIERS: "/sysadmin-plans",
    ADD_PLAN: "/sysadmin-plans/:PlanID",
    FEATURES: "/features",
    RESET_PASSWORD_OLD: "/ResetPassword",
    HOME_PATH: "/",
    TRANSFERS: "/transfers",
    AUTOMATE_TRANSFER:"/transfers/automate-transfer",
    TRANSFER_LOGS: "/transfers/logs",
    ALERTS: "/alerts",
    TEMPLATES: "/templates",
    TEMPLATES_PUBLIC: "/templates/public",
    TEMPLATES_PHONE_NUMBER: "/templates/phone-number",
    AUTOMATION_RULES: "/automation/rules",
    AUTOMATION_KEYWORD: "/automation/keyword",
    AUTOMATION_LOGS: "/automation/logs",
    AUTOMATION_RULES_GLOBAL: "/automation/rules/global-rules",
    REPORTS: "/reports/schedule-broadcast-logs",
    REPORT_VIEW: "/reports/report-view",
    PLANS_PLAN_HISTORY: "/plans/:phoneID/plan-history",
    PHONE_NUMBERS_PLAN_HISTORY: "/phone-numbers/:phoneID/plan-history",
    SWITCH_PHONE_NUMBER: "/switch-number",
    KAFKA_CLUSTER: "/kafka-cluster",
    GROUPDELETIONINFO: "/groups/group-contacts-deletion-info",
    SSO_ERROR_PAGE: "/sso-error/:state",
    SECURE_FILE: "/secure-files",
    SECURE_FILE_REDIRECT: "/securedFile",
    CreditLog: "/reports/creditLog",
    ExportReport: "/reports/exportreport",
    MESSAGE_LOGS: "/reports/message-logs",
    TEAMS_CHANNEL_SETUP: "/integrations/channel-setup",
    CLIO: "/integrations/clio",
    TEAMS_ACTIVITY_FEED: "/integrations/mst-activity-feed",
    SLACK: "/integrations/slack-setup",
    INTEGRATIIONS: "/integrations",
    CLIO_CALLBACK: "/integrations/clio",
    HUBSPOT:"/integrations/hubspot",
    HUBSPOT_CALLBACK:"/integrations/hubspot"
}

export const TeamsRoutes = {
    SIGN_IN: "/teams/sign-in",
    SIGN_UP: "/teams/sign-up",
    DASHBOARD: "/teams/dashboard",
    PLANS: "/teams/plans",
    RECHARGE: "/teams/recharge",
    USERS: "/teams/users",
    CONVERSATIONS: "/teams/conversations",
    CONVERSATION_HISTORY: "/teams/conversations/:id",
    SCHEDULED_MESSAGES: "/teams/scheduled-messages",
    CONTACTS: "/teams/contacts",
    IMPORT_CONTACTS: "/teams/contacts/import-contacts",
    IMPORT_CONTACTS_EXCEL: "/teams/contacts/import-contacts/excel",
    IMPORT_CONTACTS_OUTLOOK: "/teams/contacts/import-contacts/outlook",
    GROUPS: "/teams/groups",
    PROFILE: "/teams/profile",
    SETTINGS: "/teams/settings",
    BLOCKED: "/teams/settings/blocked",
    UNSUBSCRIBE: "/teams/settings/unsubscribe",
    SIGNATURE: "/teams/settings/signature",
    LOW_CREDIT_NOTIFICATION: "/teams/settings/low-credit-notification",
    TIMEZONE: "/teams/settings/timezone",
    SCHEDULED_REPORT: "/teams/settings/scheduledreport",
    CHANGE_PASSWORD: "/teams/change-password",
    ADD_PHONE_NUMBER: "/teams/add-phone-number",
    PAYMENT_STATUS: "/teams/payment-status",
    REHOST: "/teams/rehost",
    FORGOT_PASSWORD: "/teams/forgot-password",
    RESET_PASSWORD_NEW: "/teams/reset-password",
    TWO_FACTOR_AUTHENTICATION: "/teams/2fa",
    ORGANIZATIONS: "/teams/organizations",
    PHONE_NUMBERS: "/teams/phone-numbers",
    TIERS: "/teams/sysadmin-plans",
    ADD_PLAN: "/teams/sysadmin-plans/:PlanID",
    FEATURES: "/teams/features",
    RESET_PASSWORD_OLD: "/teams/ResetPassword",
    ALERTS: "/teams/alerts",
    AUTOMATION_RULES: "/teams/automation/rules",
    AUTOMATION_KEYWORD: "/teams/automation/keyword",
    AUTOMATION_LOGS: "/teams/automation/logs",
    HOME_PATH: "/teams",
    TRANSFERS: "/teams/transfers",
    AUTOMATE_TRANSFER:"/teams/transfers/automate-transfer",
    TRANSFER_LOGS: "/teams/transfers/logs",
    TEMPLATES: "/teams/templates",
    TEMPLATES_PUBLIC: "/teams/templates/public",
    TEMPLATES_PHONE_NUMBER: "/teams/templates/phone-number",
    AUTOMATION_RULES_GLOBAL: "/teams/automation/rules/global-rules",
    REPORTS: "/teams/reports/schedule-broadcast-logs",
    REPORT_VIEW: "/teams/reports/report-view",
    PLANS_PLAN_HISTORY: "/teams/plans/:phoneID/plan-history",
    PHONE_NUMBERS_PLAN_HISTORY: "/teams/phone-numbers/:phoneID/plan-history",
    SWITCH_PHONE_NUMBER: "/teams/switch-number",
    KAFKA_CLUSTER: "/teams/kafka-cluster",
    GROUPDELETIONINFO: "/teams/groups/group-contacts-deletion-info",
    TEAMS_LOGIN_PAGE: "/teams/tab-auth/simple-start",
    TEAMS_LOGIN_PAGE_SSO: "/teams/sso-teams-start",
    TEAMS_CONCENTPOPUP_END_SSO: "/teams/sso-teams-end",
    SSO_ERROR_PAGE: "/teams/sso-error/:state",
    TEAMS_CONCENTPOPUP_END: "/teams/tab-auth/simple-end",
    SECURE_FILE: "/teams/secure-files",
    SECURE_FILE_REDIRECT: "/teams/securedFile",
    CreditLog: "/teams/reports/creditLog",
    ExportReport: "/teams/reports/exportreport",
    MESSAGE_LOGS: "/teams/reports/message-logs",
    TEAMS_CHANNEL_SETUP: "/teams/integrations/channel-setup",
    INTEGRATIIONS: "/teams/integrations",
    CLIO: "/teams/integrations/clio",
    TEAMS_ACTIVITY_FEED: "/teams/integrations/mst-activity-feed",
    SLACK: "/teams/integrations/slack-setup",
    CLIO_POPUP: "/teams/integrations/clio/clio-popup",
    CLIO_CALLBACK: "/teams/integrations/clio",
    HUBSPOT:"/teams/integrations/hubspot",
    HUBSPOT_CALLBACK:"/teams/integrations/hubspot",
    HUBSPOT_POPUP: "/teams/integrations/hubspot/hubspot-popup",

}

export const AppRoutes = window.location.pathname.includes('teams') ? TeamsRoutes : WebRoutes;
