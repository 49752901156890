export class SecureFileModel {
    constructor() { }
    Id:string;
    FileName: string;
    Status: SecureFileStatusModel;
    UpdatedBy: string;
    UpdatedDate: string;
    UserName: string;
}

export enum SecureFileStatusModel {
    Active = "Active",
    Expired = "Expired"
}

export const mapToSecureFiles = (x: any): SecureFileModel => {
    return {
        Id:x.id,
        FileName: x.file_name,
        Status: x.status,
        UpdatedBy: x.updated_by,
        UpdatedDate: x.updated_date,
        UserName: x.user_name
    } as SecureFileModel
}

export class SecureFileInfoModel {
    constructor(){ }
    Id:string;
    Access: string[];
    ExpiryDate: string;
    FileName: string;
    FileSize: number;
    FileStatus: string;
    UploadedDate: string
}

export const mapToSecureFileInfoModel = (x) => {
    return {
        Id:x.id,
        Access: x.access,
        ExpiryDate: x.expiry_date,
        FileName: x.file_name,
        FileSize: x.file_size,
        FileStatus: x.file_status,
        UploadedDate: x.uploaded_date
    }
}

export class SecureFileAccessListModel {
    constructor() { }
    Id:string;
    AccessList: string[];
    FileName: string;
    UserID: string;
}


export const mapToSecureFileAccessListModel = (x) => {
    return {
        Id:x.id,
        AccessList: x.access_list === null ? [] : x.access_list,
        FileName: x.file_name,
        UserID: x.user_id,
    }
}

export class SecureFileLinksModel {
    constructor() { }
    Id:string;
    FileName: string;
    Url: string;
    Status: SecureFileStatusModel;
}

export const mapToSecureFileNameWithLinksModel = (x) => {
    return {
        Id:x.id,
        FileName: x.file_name,
        Url: x.url,
        Status: x.status
    }
}
