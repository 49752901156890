import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import chargebeeService from './chargebee.service';


const getPhoneNumbers = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers`);
};

const getPhoneNumbersByOrgId = (id) => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/grid/${id}`);
};


const getPhoneNumbersGridData = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/grid`);
};


const getProviders = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/providers`);
};


const getPhoneNumberById = (orgId: any) => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/${orgId}`);
};

const updatePhoneNumber = (data: any) => {
    return AxiosInstance.put(`${Config.API_URL}/api/PhoneNumbers`, data);
};

const createPhoneNumber = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers`, data);
};

const deletePhoneNumbers = (id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/PhoneNumbers/${id}`);
};


const RechargeHistory = (phoneId: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/${phoneId}/RechargeHistory`)
}

const sendVoiceOTP = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/Host?sendOtp=${true}`, data)
}

const verifyVoiceOTP = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/Host?sendOtp=${false}`, data)
}

const unHostPhoneNumber = (id: string, subscriptionId: string) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/UnHost?id=${id}`).then((res) => {
        chargebeeService.cancelSubscription(subscriptionId);
        return res;
    })
}

const getSubscriberDetails = (phoneId: any) => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/${phoneId}/Details`)
}

const reHostPhoneNumberSendVoiceOTP = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/ReHost?sendOtp=${true}`, data)
}

const reHostPhoneNumberVerifyVoiceOTP = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/ReHost?sendOtp=${false}`, data)
}

const checkPhoneNumberStatus = (id: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/${id}/HostingStatus`);
}

const transferCredits = (fromPhoneId: string, toPhoneId: string, type: string, credits: number) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/${fromPhoneId}/CreditTransfer/${toPhoneId}/type?type=${type}&credits=${credits}`);
}

const getPhonePlanTracker = (id: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/${id}/Tracker`);
}

const getCreditTransferHistory = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/CreditTransferHistory`);
}

const postCreditTransferRule = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/CreditTransferRule`, data);
};

const getCreditTransferRule = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/CreditTransferRule`);
}

const updateCreditTransferRule = (data: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/PhoneNumbers/CreditTransferRule`, data);
}

const deleteCreditTransferRule = (id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/PhoneNumbers/CreditTransferRule?id=${id}`);
}

const manualPayment = (id: string, data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/${id}/ManualRecharge`, data);
}

const getGridByOrganizations = (data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/PhoneNumbers/gridByOrganizations`, data);
}

const GetUnreadCount = ()=>{
    return AxiosInstance.get(`${Config.API_URL}/api/PhoneNumbers/UnreadCount`);
}


export const PhoneNumberService = {
    getPhoneNumbers,
    getPhoneNumbersGridData,
    getPhoneNumberById,
    updatePhoneNumber,
    createPhoneNumber,
    deletePhoneNumbers,
    getProviders,
    RechargeHistory,
    sendVoiceOTP,
    verifyVoiceOTP,
    unHostPhoneNumber,
    reHostPhoneNumberSendVoiceOTP,
    reHostPhoneNumberVerifyVoiceOTP,
    getSubscriberDetails,
    checkPhoneNumberStatus,
    transferCredits,
    getPhonePlanTracker,
    getCreditTransferHistory,
    manualPayment,
    getPhoneNumbersByOrgId,
    getGridByOrganizations,
    postCreditTransferRule,
    getCreditTransferRule,
    updateCreditTransferRule,
    deleteCreditTransferRule,
    GetUnreadCount,
};